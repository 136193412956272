const Logo = ({ small, url, alt, onClick }) => {
  const width = small ? 30 : 50
  const height = width
  const borderRadius = 1000
  const cursor = 'pointer'
  const commonStyle = { width, height, borderRadius, cursor }
  return (
    <div onClick={onClick} style={{ ...commonStyle, background: '#979797' }}>
      {url && <img alt={alt} src={url} style={commonStyle} />}
    </div>
  )
}

export default Logo
