import { type } from '../services/workedTime'
export const sum = (workedTime, hourPerDay = 5) => {
  if (!workedTime) return { days: 0, hours: 0 }

  const time = workedTime.map(t => t.info).filter(_ => _)
  if (time.length < 0) return { days: 0, hours: 0 }

  const days = time.filter(wt => wt.type === type.DAY)
  const half = time.filter(wt => wt.type === type.HALF_DAY)
  const hours = time.filter(wt => wt.type === type.HOURS)

  const sumHours = hours.reduce((acc, val) => acc + val.hours, 0)
  const totalHours = Math.floor(
    sumHours + (days.length + half.length / 2) * hourPerDay
  )
  const totalDays = Math.floor(
    days.length + half.length / 2 + sumHours / hourPerDay
  )

  return { days: totalDays, hours: totalHours }
}
