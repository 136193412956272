export const user = state => {
  return state.settings
}

export const projects = state => {
  console.log(state)
  return state.projects
}

export const project = id => state => {
  return state.projects.find(p => p.id === id)
}

export const profile = state => {
  const profile = state.settings.userInfo ?? {}
  const user = state.settings.user
  return { profile, user }
}
