import styles from './Switch.module.css'

const Switch = ({ active, onClick, className }) => {
  const act = active ? styles.activeSwitch : styles.inactiveSwitch
  const actPoint = active ? styles.activePoint : styles.inactivePoint
  const click = () => onClick && onClick(!active)
  return (
    <div className={[act, className].join(' ')} onClick={click}>
      <div className={actPoint} />
    </div>
  )
}

export default Switch
