const colors = [
  'blue',
  'red',
  'yellow',
  'cyan',
  'green',
  'orange',
  'pink',
  'purple',
]

let forRandom = [...colors]

export const randomColor = remise => {
  if (remise) {
    return colors[Math.floor(Math.random() * 8)]
  } else {
    const index = Math.floor(Math.random() * forRandom.length)
    const color = forRandom[index]
    forRandom = forRandom.filter((_, i) => i !== index)
    return color
  }
}

const toDoubleHexa = n => (n < 16 ? '0' + n.toString(16) : n.toString(16))

export const rgbaTorgb = (rgb, alpha = 0) => {
  let r = 0,
    g = 0,
    b = 0
  if (rgb.startsWith('#')) {
    const hexR = rgb.length === 7 ? rgb.slice(1, 3) : rgb[1]
    const hexG = rgb.length === 7 ? rgb.slice(3, 5) : rgb[2]
    const hexB = rgb.length === 7 ? rgb.slice(5, 7) : rgb[3]
    r = parseInt(hexR, 16)
    g = parseInt(hexG, 16)
    b = parseInt(hexB, 16)
  }
  if (rgb.startsWith('rgb')) {
    const val = rgb.replace(/(rgb)|\(|\)| /g, '')
    const splitted = val.split(',')
    r = parseInt(splitted[0])
    g = parseInt(splitted[1])
    b = parseInt(splitted[2])
  }

  r = Math.max(Math.min(Math.floor((1 - alpha) * r + alpha * 255, 255)), 0)
  g = Math.max(Math.min(Math.floor((1 - alpha) * g + alpha * 255, 255)), 0)
  b = Math.max(Math.min(Math.floor((1 - alpha) * b + alpha * 255, 255)), 0)

  return '#' + toDoubleHexa(r) + toDoubleHexa(g) + toDoubleHexa(b)
}

const generateCSSPalette = (name, alpha) => `--${name}-blue: ${rgbaTorgb(
  '#22b8da',
  alpha
)};
--${name}-red: ${rgbaTorgb('#cf4860', alpha)};
--${name}-yellow: ${rgbaTorgb('#f4c54d', alpha)};
--${name}-cyan: ${rgbaTorgb('#04e0d0', alpha)};
--${name}-green: ${rgbaTorgb('#9cc63d', alpha)};
--${name}-orange: ${rgbaTorgb('#d77a51', alpha)};
--${name}-pink: ${rgbaTorgb('#e69dbb', alpha)};
--${name}-purple: ${rgbaTorgb('#7a7acf', alpha)};`

// console.log(`
// --blue: ${rgbaTorgb('#22b8da')};
// --red: ${rgbaTorgb('#cf4860')};
// --yellow: ${rgbaTorgb('#f4c54d')};
// --cyan: ${rgbaTorgb('#04e0d0')};
// --green: ${rgbaTorgb('#9cc63d')};
// --orange: ${rgbaTorgb('#d77a51')};
// --pink: ${rgbaTorgb('#e69dbb')};
// --purple: ${rgbaTorgb('#7a7acf')};
// ${generateCSSPalette('lighten', 0.5)}
// ${generateCSSPalette('darken', -0.5)}
// `)
