import styles from './Profile.module.css'
import goPayment from './go-payment.svg'
import * as Icons from '../../icons'
import mastercard from './mastercard.svg'

const cards = [
  {
    last4: '1234',
    expiringDate: '03/22',
    brand: 'mastercard',
    name: 'Guillaume Hivert',
  },
]

const PaymentCard = ({ last4, expiringDate, brand, name }) => {
  return (
    <code className={styles.paymentCard}>
      <Icons.Chip className={styles.paymentChip} />
      <div className={styles.paymentName}>{name}</div>
      <div className={styles.paymentLast4}>{`XXXX XXXX XXXX ${last4}`}</div>
      <div className={styles.paymentDate}>{expiringDate}</div>
      <img className={styles.paymentBrand} src={mastercard} alt="mastercard" />
    </code>
  )
}

const AddACard = () => {
  return (
    <button onClick={() => console.log('addcard')} className={styles.addCard}>
      <div className={styles.addCardText}>Ajouter une nouvelle carte</div>
      <Icons.Plus className={styles.addCardPlus} />
    </button>
  )
}

const Payment = () => {
  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.header}>
        <div className={styles.placeholder} />
        <div className={styles.headerTitle}>Paiement</div>
        <button className={styles.toggler} onClick={() => console.log('edit')}>
          <Icons.Pencil className={styles.headerEdit} />
        </button>
      </div>
      <div className={styles.paymentBody}>
        <div className={styles.paymentCards}>
          {cards.map((card, index) => (
            <PaymentCard key={index} {...card} />
          ))}
          <AddACard />
        </div>
        <img className={styles.goPayment} src={goPayment} alt="Go payment" />
      </div>
    </div>
  )
}

export default Payment
