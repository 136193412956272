import { sum } from './time'

export const ALL = 'ALL'
export const MONTHS = 'MONTHS'
export const THIRTYD = 'THIRTYD'
export const WEEK = 'WEEK'
export const CUSTOM = 'CUSTOM'

const MILLISECOND_PER_DAY = 1000 * 60 * 60 * 24
const MILLISECOND_PER_WEEK = 1000 * 60 * 60 * 24 * 7
const MILLISECOND_PER_MONTH = 1000 * 60 * 60 * 24 * 30 //ouai je sais mais flemme
const MILLISECOND_PER_YEAR = 1000 * 60 * 60 * 24 * 365
const STEPS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
}

const getStart = ({ type, end }) => {
  const date = new Date(end)
  switch (type) {
    case MONTHS:
      date.setDate(1)
      return date
    case THIRTYD:
      date.setMonth(date.getMonth() - 1)
      return date
    case WEEK:
      return new Date(end.getFullYear(), end.getMonth(), end.getDate() - 7)
    default:
      return date
  }
}

const getNbIntervals = ({ type, start, end }) => {
  switch (type) {
    case STEPS.YEAR:
      return Math.ceil(Math.abs(end - start) / MILLISECOND_PER_YEAR)
    case STEPS.MONTH:
      let months
      months = (end.getFullYear() - start.getFullYear()) * 12
      months -= start.getMonth()
      months += end.getMonth()
      return months <= 0 ? 0 : months
    case STEPS.WEEK:
      return Math.ceil(Math.abs(end - start) / MILLISECOND_PER_WEEK)
    case STEPS.DAY:
      return Math.ceil(Math.abs(end - start) / MILLISECOND_PER_DAY)
    default:
      return 0
  }
}

const getStep = (plageWindow = 0) => {
  if (plageWindow > 750) return STEPS.YEAR
  if (plageWindow > 300) return STEPS.MONTH
  if (plageWindow > 32) return STEPS.WEEK
  return STEPS.DAY
}

const typeToMilli = type => {
  switch (type) {
    case STEPS.YEAR:
      return MILLISECOND_PER_YEAR
    case STEPS.MONTH:
      return MILLISECOND_PER_MONTH
    case STEPS.WEEK:
      return MILLISECOND_PER_WEEK
    case STEPS.DAY:
      return MILLISECOND_PER_DAY
    default:
      return 0
  }
}

const generateIntervals = ({ type, start, nb = 0 }) => {
  switch (type) {
    case STEPS.YEAR:
      return []
    case STEPS.MONTH:
      return []
    case STEPS.WEEK:
      const monday = new Date(start)
      monday.setDate(start.getDate() - ((start.getDay() + 6) % 7))
      const week = Array(nb + 1)
        .fill(1)
        .map(
          (_, index) => new Date(monday.getTime() + index * typeToMilli(type))
        )
      return week
    case STEPS.DAY:
      const day = Array(nb)
        .fill(1)
        .map(
          (_, index) => new Date(start.getTime() + index * typeToMilli(type))
        )
      return day
    default:
      return []
  }
}

export const getPlage = ({ type, end, start: forcedStart, timeseries }) => {
  const start = forcedStart ?? getStart({ type, end })
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  end.setHours(0)
  end.setMinutes(0)
  end.setSeconds(0)
  const nbDays = Math.ceil(Math.abs(end - start) / MILLISECOND_PER_DAY)
  const step = getStep(nbDays)
  const nbInterval = getNbIntervals({ type: step, start, end })
  const intervals = generateIntervals({ type: step, start, nb: nbInterval })

  const data = createTimeSeries({ type: step, plage: intervals, timeseries })
  return { intervals, data }
}

const groupByStep = ({ steps, type, timeseries }) => {
  const mili = typeToMilli(type)
  const data = steps
    .map(step =>
      timeseries.filter(t => Math.abs(new Date(t.date) - step) <= mili)
    )
    .map(arr => sum(arr.map(v => ({ info: v }))))
    .reduce(
      (acc, val) => {
        acc.days.push(val.days)
        acc.hours.push(val.hours)
        return acc
      },
      { days: [], hours: [] }
    )
  return data
}

export const createTimeSeries = ({ type, plage, timeseries = [] }) => {
  const groupedData = groupByStep({
    type,
    steps: plage,
    timeseries: timeseries.map(wt => wt.info),
  })

  return groupedData
}
