import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import OnBoarding from './OnBoarding'
import { useSelector, useDispatch } from 'react-redux'
import * as selectors from '../store/selectors'
import Login from './Login'
import Profile from './Profile'
import { ProjectRoutes } from './Project'
import NotFound from './NotFound'
import { fetchProjects } from '../store/actions/project'
import { fetchWorkedTime } from '../store/actions/workedTime'

const AllRoutes = () => {
  const { user, userInfo } = useSelector(selectors.user)
  const dispatch = useDispatch()
  const HomeComponent = user.uid ? Home : Login

  useEffect(() => {
    if (userInfo) {
      dispatch(fetchProjects()).then(() => {
        dispatch(fetchWorkedTime())
      })
    }
  }, [dispatch, userInfo])

  return (
    <Routes>
      <Route path="/onboarding" element={<OnBoarding />} />
      <Route path="/profile/*" element={<Profile />} />
      <Route path="/project/*" element={<ProjectRoutes />} />
      <Route path="/" element={<HomeComponent />} />
      <Route element={NotFound} />
    </Routes>
  )
}

export default AllRoutes
