const SvgComponent = props => (
  <svg
    width={52}
    height={28}
    viewBox="0 0 52 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{'noun_team_3202855'}</title>
    <g fill="var(--text-color)" fillRule="nonzero">
      <path d="M16.8 16.208c.215-.162.43-.27.646-.377-2.208-.916-5.654-1.023-6.73-1.023-1.293 0-5.654.107-7.647 1.454-2.53 1.776-2.8 7.215-2.8 8.346 0 .323.108.646.377.915.216.215.539.377.862.377h11.415c.162-2.638.808-7.592 3.877-9.692z" />
      <circle cx={10.715} cy={6.515} r={6.354} />
      <path d="M51.423 24.446c-.054-1.4-.377-6.461-2.854-8.13-1.992-1.347-6.354-1.454-7.646-1.454-1.13 0-4.577.107-6.73 1.023.215.107.43.269.645.377 3.016 2.046 3.77 6.73 3.877 9.638h11.416c.7 0 1.238-.538 1.238-1.238.054-.108.054-.162.054-.216z" />
      <circle cx={40.923} cy={6.515} r={6.354} />
      <path d="M33.492 18.254C31.5 16.908 27.138 16.8 25.846 16.8c-1.292 0-5.654.108-7.646 1.454-2.53 1.723-2.8 7.215-2.854 8.292 0 .323.108.646.377.916.215.215.539.376.862.376h18.469c.7 0 1.238-.538 1.238-1.238v-.215c-.054-1.347-.377-6.462-2.8-8.131z" />
      <circle cx={25.846} cy={8.508} r={6.354} />
    </g>
  </svg>
)

export default SvgComponent
