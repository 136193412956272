const SvgComponent = props => (
  <svg
    width={701}
    height={581}
    viewBox="0 0 701 581"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M-349.309-60.448C-432.858-60.448-552-137.682-552-197.806-552-261.354-482.918-309-386.162-309c95.307 0 147.412 45.287 147.412 111.194 0 30.734-4.797 59.043-16.019 81.839-16.66 33.845-48.01 55.519-94.54 55.519zm885.77-130.66c46.665 58.312 32.24 105.292-28.688 188.33-36.73 50.059-46.317 66.663-46.317 86.228 0 17.41 23.215 36.387 82.697 68.379 3.293 1.77 6.754 3.62 11.04 5.9l11.275 5.992C663.985 215.574 701 244.642 701 292.757c0 141.983-87.122 279.21-255.348 287.73-91.922 4.656-147.348-22.15-229.78-90.74-3.513-2.923-15.393-12.864-16.91-14.129-56.826-47.41-90.188-68.197-136.578-79.662-48.408-11.964-87.977-8.648-177.789 9.573-36.583 7.422-51.742 10.281-72.167 13.216-77.03 11.068-139.778 5.02-201.532-26.182-68.45-34.585-78.833-81.05-37.731-127.82 28.9-32.883 80.386-64.948 156.605-102.374 16.524-8.113 33.487-16.139 54.868-26.038 1.666-.772 38.962-17.954 48.465-22.4 37.07-17.344 56.09-50.832 85.503-137.393l.486-1.429c18.201-53.568 27.53-76.78 43.71-102.615 23.235-37.102 54.195-62.524 98.008-74.969 105.948-30.093 175.812-46.069 247.18-52.331 104.674-9.185 184.782 9.104 228.471 63.699z"
      fill="var(--background-card)"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgComponent
