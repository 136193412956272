import { NavLink } from 'react-router-dom'
import styles from './ProfileLink.module.css'

const computeStyle = color => ({ isActive }) => {
  const borderBottom = `2px solid ${color}`
  const background = isActive ? color : undefined
  const clr = isActive ? 'white' : undefined
  return { borderBottom, background, '--text-color': clr }
}

export const ProfileLink = ({ title, to, icon, color, onClick }) => {
  const Icon = icon
  return (
    <NavLink
      replace
      end
      to={to}
      className={styles.navItem}
      style={computeStyle(color)}
      onClick={onClick}
    >
      <Icon className={styles.navItemIcon} />
      {title}
    </NavLink>
  )
}
