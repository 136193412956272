import * as types from '../types'
import * as auth0 from '../../services/auth0'
import * as userInfo from '../../services/user-info'

export const login = (user, token) => async dispatch => {
  await dispatch({ payload: { user, token }, type: types.LOGIN })
  const body = await userInfo.get(token)
  if (body) await dispatch({ payload: body, type: types.UPDATE_USER_INFO })
  return body
}

export const logout = auth => async dispatch => {
  await dispatch({ payload: undefined, type: types.LOGOUT })
  await auth0.logout(auth)
}
