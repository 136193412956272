const Pencil = props => (
  <svg width="26px" height="27px" viewBox="0 0 26 27" {...props}>
    <g
      id="Dashboard-web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop-HD-Copy-14"
        transform="translate(-1287.000000, -249.000000)"
        fill="var(--text-color)"
        fillRule="nonzero"
      >
        <g
          id="noun_write_1577968"
          transform="translate(1287.000000, 249.000000)"
        >
          <g id="Group" transform="translate(0.000000, 0.233349)">
            <path
              d="M21.8940549,0.132504878 C20.9455994,0.132504878 20.0026701,0.491877439 19.2759146,1.21863293 L17.8696646,2.62488293 L23.117378,7.87259634 L24.523628,6.46634634 C25.9771354,5.01283537 25.9771427,2.67214024 24.523628,1.21863293 C23.7968726,0.49187561 22.8425104,0.132504878 21.8940549,0.132504878 Z M16.8292683,3.66527927 L2.50381098,17.9907366 L0.731707317,25.0105537 L7.75152439,23.23845 L22.0769817,8.91299268 L16.8292683,3.66527927 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Pencil
