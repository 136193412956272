import { Routes, Route, NavLink } from 'react-router-dom'
import Informations from './Informations'
import Payment from './Payment'
import { useProtectedRoute } from '../../helpers/auth'
import Layout from '../../components/layout'
import Card from '../../components/card'
import styles from './Profile.module.css'
import * as Icons from '../../icons'
import { ProfileLink } from '../../components/profile-link'
import faker from 'faker'
faker.locale = 'cz'

const people = new Array(100).fill(1).map(_ => ({
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  job: faker.name.jobTitle(),
}))

const Team = () => {
  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.header}>
        <div className={styles.placeholder} />
        <div className={styles.headerTitle}>Équipe</div>
        <button className={styles.toggler} onClick={() => console.log('plus')}>
          <Icons.Plus className={styles.headerEdit} />
        </button>
      </div>
      <div className={styles.peopleWrapper}>
        {people.map(({ firstName, lastName, job }, index) => (
          <div className={styles.peopleRow} key={index}>
            <div className={styles.peopleName}>{firstName}</div>
            <div className={styles.peopleName}>{lastName}</div>
            <div className={styles.peopleJob}>{job}</div>
            <button onClick={() => console.log('people', index)}>
              <Icons.Cross className={styles.peopleCross} />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

const Profile = () => {
  useProtectedRoute()
  return (
    <Layout title="Profil">
      <Card color="rgba(89, 89, 89, 0.25)">
        <Card.Nav>
          <div className={styles.nav}>
            <ProfileLink to="/profile" icon={Icons.Note} title="Infos" />
            <ProfileLink
              to="/profile/payment"
              icon={Icons.Payment}
              title="Paiement"
            />
            <ProfileLink to="/profile/team" icon={Icons.Team} title="Équipe" />
          </div>
        </Card.Nav>
        <Card.Body>
          <Routes>
            <Route path="/" element={<Informations />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/team" element={<Team />} />
          </Routes>
        </Card.Body>
      </Card>
    </Layout>
  )
}

export default Profile
