export const getToken = auth0 => auth0.getgetAccessTokenSilently({})

export const login = (auth0, connection) => {
  const options = connection ? { connection } : undefined
  return auth0.loginWithRedirect(options)
}

export const logout = auth0 => {
  const returnTo = window.location.origin
  return auth0.logout({ returnTo })
}
