import React from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../../../components/card'
import styles from './Project.module.css'
import { sum } from '../../../helpers/time'
import * as Icons from '../../../icons'

const timeToString = time => {
  const { hours, days } = sum(time)
  return `${hours}h / ${days}j`
}

const Project = ({ project }) => {
  const formatter = new Intl.DateTimeFormat(navigator.language)
  const navigate = useNavigate()
  const { ownerInfo, info, createdAt } = project
  const { collaborators } = info
  const ownerName = `${ownerInfo.firstName} ${ownerInfo.lastName}`
  const plural = collaborators.length > 0 ? 's' : ''
  const collabs = `${collaborators.length} collaborateur${plural}`
  const createdDate = formatter.format(createdAt)
  const navigateToProject = () => navigate(`/project/${project.id}`)
  return (
    <Card color={`var(--${project.info.color})`} shadow="light">
      <Card.Body row align="center">
        <button className={styles.row} onClick={navigateToProject}>
          <div className={styles.title}>{project.info.title}</div>
          <div className={styles.collaborators}>{collabs}</div>
          <div className={styles.owner}>{ownerName}</div>
          <div className={styles.time}>{timeToString(project.workedTime)}</div>
          <div className={styles.createdAt}>{`depuis le ${createdDate}`}</div>
          <div className={styles.path}>
            <Icons.ArrowRight />
          </div>
        </button>
      </Card.Body>
    </Card>
  )
}

export default Project
