import styles from './Button.module.css'

const Button = ({ title, primary, cancel, disabled, small, ...props }) => {
  const className = primary
    ? styles.primary
    : cancel
    ? styles.cancel
    : disabled
    ? styles.disabled
    : styles.button
  const cl = `${className} ${small && styles.small}`
  return (
    <button className={cl} disabled={disabled} {...props}>
      {title}
    </button>
  )
}

export default Button
