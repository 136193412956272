import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as userActions from '../../store/actions/user'
import * as selectors from '../../store/selectors'
import TextInput from '../../components/text-input'
import Button from '../../components/button'
import { Pencil } from '../../icons'
import styles from './Profile.module.css'

const empty = {
  company: '',
  address: '',
  siret: '',
  job: '',
  firstName: '',
  lastName: '',
}

const Informations = () => {
  const dispatch = useDispatch()
  const { profile, user } = useSelector(selectors.profile)
  const [editable, setEditable] = useState(false)
  const emptyState = useMemo(() => {
    return { ...empty, ...profile.info }
  }, [profile])
  useEffect(() => {
    setEdition(emptyState)
  }, [emptyState])
  const [edition, setEdition] = useState(emptyState)
  const onToggle = () => setEditable(true)
  const onCancel = () => {
    setEditable(false)
    setEdition(emptyState)
  }
  const onValidate = async () => {
    setEditable(false)
    const action = userActions.updateUserInfo(edition)
    await dispatch(action)
  }
  const onEdit = field => value => {
    setEdition(edition => ({ ...edition, [field]: value }))
  }
  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.header}>
        <div className={styles.placeholder} />
        <div className={styles.headerTitle}>Informations</div>
        <button className={styles.toggler} onClick={onToggle}>
          <Pencil
            className={styles.headerEdit}
            style={{ background: editable && 'rgba(207, 207, 207, 0.4)' }}
          />
        </button>
      </div>
      <div className={styles.infosBody}>
        <TextInput
          value={edition.firstName}
          label="Prénom"
          placeholder="John"
          disabled={!editable}
          onChange={onEdit('firstName')}
        />
        <TextInput
          value={edition.lastName}
          label="Nom"
          placeholder="Doe"
          disabled={!editable}
          onChange={onEdit('lastName')}
        />
        <TextInput
          value={edition.job}
          label="Fonction"
          placeholder="Manager"
          disabled={!editable}
          onChange={onEdit('job')}
        />
        <TextInput
          value={edition.company}
          label="Nom de l'entreprise"
          placeholder="Dunder-Mifflin"
          disabled={!editable}
          onChange={onEdit('company')}
        />
        <TextInput
          value={edition.address}
          label="Adresse"
          placeholder="Rue des Champs-Élysées"
          disabled={!editable}
          onChange={onEdit('address')}
        />
        <TextInput
          value={edition.siret}
          label="Siret"
          placeholder="123 456 789 00000"
          disabled={!editable}
          onChange={value => {
            const matched = value.match(/^[0-9 ]*$/)
            if (matched) onEdit('siret')(value)
          }}
        />
      </div>
      {editable && (
        <div className={styles.validate}>
          <Button onClick={onCancel} cancel title="Annuler" />
          <Button onClick={onValidate} primary title="Valider" />
        </div>
      )}
    </div>
  )
}

export default Informations
