import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Toggler from '../../../components/switch'
import TextInput from '../../../components/text-input'
import DateInput from '../../../components/date-input'
import Button from '../../../components/button'
import { useModal } from '../../../components/modal'
import * as selector from '../../../store/selectors'
import * as Icons from '../../../icons'
import styles from '../Project.module.css'
import { Pencil } from '../../../icons'
import * as projectActions from '../../../store/actions/project'

const ArchiveModal = ({ close }) => (
  <div className={styles.archiveModal}>
    <div className={styles.archiveTitle}>
      Êtes-vous sûr de vouloir archiver le projet ?
    </div>
    <div className={styles.archiveSubTitle}>
      Aucun collaborateur ne pourra plus ajouter d'heure par la suite
    </div>
    <div className={styles.archiveButtons}>
      <Button title="Non" small cancel onClick={() => close(false)} />
      <Button title="Oui" small onClick={() => close(true)} />
    </div>
  </div>
)

export const Informations = () => {
  const { id } = useParams()
  const project = useSelector(selector.project(id))
  const dispatch = useDispatch()
  const onToggle = () => setEditable(true)
  const [editable, setEditable] = useState(false)
  const modal = useModal()
  const emptyProject = {
    title: project.info.title ?? '',
    client: project.info.client ?? '',
    createdAt: project.createdAt ?? new Date(),
  }
  const [edition, setEdition] = useState(emptyProject)
  const onEdit = field => value => {
    setEdition(edition => ({ ...edition, [field]: value }))
  }
  const onCancel = () => {
    setEdition(emptyProject)
    setEditable(false)
  }
  const updateProject = async () => {
    const action = projectActions.updateProjectTitle({ id, ...edition })
    await dispatch(action)
    setEditable(false)
  }
  const [archived, setArchived] = useState(false)
  const onArchive = async () => {
    if (!archived) {
      const options = { transparent: true }
      const shouldArchive = await modal.show(ArchiveModal, options)
      if (shouldArchive) {
        // TODO ask in database
        setArchived(true)
      }
    } else {
      // TODO ask in database
      setArchived(false)
    }
  }
  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Informations</div>
        <button className={styles.toggler} onClick={onToggle}>
          <Pencil
            className={styles.headerEdit}
            style={{ background: editable && 'rgba(207, 207, 207, 0.4)' }}
          />
        </button>
      </div>
      <div className={styles.infosBody}>
        <div />
        <TextInput
          value={edition.title}
          label="Nom du projet"
          placeholder="Nom du projet"
          disabled={!editable}
          onChange={onEdit('title')}
        />
        <DateInput
          value={edition.createdAt}
          label="Date du projet"
          placeholder="Doe"
          disabled={true}
          onChange={onEdit('createdAt')}
        />
        {/* <TextInput
            value={edition.client}
            label="Client du projet"
            placeholder="client"
            disabled={!editable}
            onChange={onEdit('client')}
          /> */}
        {editable && (
          <div className={styles.buttons}>
            <Button title="Annuler" cancel onClick={onCancel} />
            <Button title="Valider" primary onClick={updateProject} />
          </div>
        )}
        <Icons.BookGirl
          bookColor={`var(--${project.info.color})`}
          bookStrapColor={`var(--darken-${project.info.color})`}
          className={styles.infosGirl}
        />
        <div className={styles.toggleArchive}>
          <Toggler active={archived} onClick={onArchive} />
          <div className={styles.archive}>Archiver la mission</div>
        </div>
      </div>
    </div>
  )
}
