import * as types from '../types'

const initialState = []

const fetchProjectWorkedTime = (projects, { id, workedTime }) => {
  const index = projects.findIndex(project => id === project.id)
  const project = projects[index]
  projects[index] = { ...project, workedTime }
  return [...projects]
}

const fetchProjectData = (projects, payload) => {
  const index = projects.findIndex(project => payload.id === project.id)
  const project = projects[index]
  projects[index] = { ...project, ...payload }
  return [...projects]
}

const updateProject = (projects, payload) => {
  const index = projects.findIndex(project => payload.id === project.id)
  projects[index] = payload
  return [...projects]
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PROJECTS:
      return [...state, ...action.payload]
    case types.FETCH_PROJECT_DATA:
      return fetchProjectData(state, action.payload)
    case types.FETCH_PROJECT_WORKED_TIME:
      return fetchProjectWorkedTime(state, action.payload)
    case types.UPDATE_PROJECT:
      return updateProject(state, action.payload)
    default:
      return state
  }
}

export default reducer
