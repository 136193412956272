import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as selectors from './store/selectors'
import styles from './App.module.css'
import Routes from './views'
import Login from './views/Login'

const App = () => {
  const { user } = useSelector(selectors.user)
  return (
    <main className={styles.main}>
      <BrowserRouter>{user ? <Routes /> : <Login />}</BrowserRouter>
    </main>
  )
}

export default App
