const SvgComponent = props => (
  <svg
    width={42}
    height={42}
    viewBox="0 0 42 42"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.846 36.94c1.95 1.952-.985 4.823-2.907 2.907L20.753 23.658 4.566 39.846a2.049 2.049 0 01-1.453.622c-1.674 0-2.858-2.122-1.453-3.528l16.188-16.187L1.66 4.566c-1.934-1.932.973-4.84 2.906-2.906l16.187 16.188L36.94 1.66c1.936-1.936 4.838.974 2.907 2.906L23.658 20.753 39.846 36.94z"
      fill="var(--text-color)"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgComponent
