import * as Icons from '../../../icons'
import styles from '../Project.module.css'
import faker from 'faker'

const people = new Array(100).fill(1).map(_ => ({
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  job: faker.name.jobTitle(),
}))

const groupByJob = people => {
  return people.reduce((acc, p) => {
    const index = p.job
    const peoples_ = acc[index] ?? []
    return { ...acc, [index]: [...peoples_, p] }
  }, {})
}

export const Team = () => {
  const peoples = groupByJob(people)
  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.header}>
        <div className={styles.placeholder} />
        <div className={styles.headerTitle}>Équipe</div>
        <button className={styles.toggler} onClick={() => console.log('plus')}>
          <Icons.Plus className={styles.headerEdit} />
        </button>
      </div>
      <div className={styles.peopleWrapper}>
        {Object.entries(peoples).map(([job, people]) => (
          <>
            <div className={styles.peopleJobSection}>{job}</div>
            {people.map(({ firstName, lastName, job }, index) => (
              <div className={styles.peopleRow} key={index}>
                <div className={styles.peopleName}>{firstName}</div>
                <div className={styles.peopleName}>{lastName}</div>
                <div className={styles.peopleJob}>{job}</div>
                <button onClick={() => console.log('people', index)}>
                  <Icons.Cross className={styles.peopleCross} />
                </button>
              </div>
            ))}
          </>
        ))}
      </div>
    </div>
  )
}
