const SvgComponent = props => (
  <svg width={20} height={42} viewBox="0 0 20 42" {...props}>
    <path
      d="M.19 38.272L13.947 21 .19 3.728A.941.941 0 010 3.151a.946.946 0 01.184-.58L1.99.239c.245-.316.639-.318.885-.008l16.026 20.187c.121.153.19.363.19.582 0 .22-.069.43-.19.582L2.875 41.77a.564.564 0 01-.44.231.565.565 0 01-.445-.238L.184 39.43A.946.946 0 010 38.85a.941.941 0 01.19-.578z"
      fill="var(--text-color)"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgComponent
