import faker from 'faker'
import env from '../envs'
import store from '../store'
import { bearer } from './token'
import { randomColor } from '../helpers/colors'
const route = 'time/worked-time'

export const type = {
  HALF_DAY: 'HALF_DAY',
  HOURS: 'HOURS',
  DAY: 'DAY',
}

const randomPid = () =>
  store.getState().projects.projects[
    Math.floor(Math.random() * store.getState().projects.projects.length)
  ].id

const randomType = () => {
  const val = Math.floor(Math.random() * 3)
  switch (val) {
    case 0:
      return type.DAY
    case 1:
      return type.HALF_DAY
    default:
      return type.HOURS
  }
}

const randomTimeEntry = () => ({
  date: faker.date.past(),
  type: randomType(),
  hours: Math.floor(Math.random() * 3) + 1,
})

export const get = async (token, project) => {
  const options = { headers: bearer(token) }
  const endpoint = [env.audience, route, 'project'].join('/')
  const parameters = `project=${project}`
  const response = await fetch(`${endpoint}?${parameters}`, options)
  return response.json()
}

export const getAll = async (token, id) => {
  const options = { headers: bearer(token) }
  const parameters = `owner=${id}`
  const endpoint = [env.audience, route, 'all'].join('/')
  const response = await fetch(`${endpoint}?${parameters}`, options)
  return response.json()
}

export const update = async (token, body) => {
  const method = 'POST'
  const options = { headers: bearer(token), body: JSON.stringify(body), method }
  const endpoint = [env.audience, route, 'create'].join('/')
  const response = await fetch(endpoint, options)
  return response.json()
}
