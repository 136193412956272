const SvgComponent = props => (
  <svg width={709} height={385} viewBox="0 0 709 385" {...props}>
    <title>{'noun_stain_3354281 copy 3'}</title>
    <path
      d="M70.392 122.328c61.786-34.551 111.564-23.87 199.549 21.242 53.042 27.196 70.635 34.294 91.366 34.294 18.448 0 38.555-17.189 72.452-61.23 1.877-2.439 3.836-5.002 6.252-8.175 1.238-1.626 3.157-4.15 6.349-8.348C501.302 27.907 532.103.5 583.084.5c150.442 0 295.844 64.507 304.873 189.066 4.933 68.061-23.47 109.1-96.147 170.135-3.097 2.6-13.63 11.397-14.97 12.52-50.235 42.076-72.26 66.778-84.409 101.126-12.676 35.843-9.163 65.14 10.144 131.64 7.864 27.086 10.894 38.31 14.003 53.433 11.728 57.035 5.32 103.496-27.742 149.22-36.646 50.682-85.88 58.37-135.434 27.937-34.843-21.398-68.818-59.52-108.474-115.955-8.597-12.234-17.1-24.794-27.59-40.625-.817-1.234-19.023-28.849-23.734-35.885-18.377-27.448-53.86-41.53-145.578-63.308l-1.515-.36c-56.759-13.476-81.354-20.383-108.728-32.363-39.313-17.205-66.249-40.128-79.435-72.569C26.46 396.066 9.534 344.337 2.898 291.495c-9.732-77.504 9.647-136.818 67.494-169.167z"
      fill="var(--background-light)"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgComponent
