import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Localized } from '@fluent/react'
import { useDispatch, useSelector } from 'react-redux'
import * as userActions from '../../store/actions/user'
import Layout from '../../components/on-boarding-layout'
import TextInput from '../../components/text-input'
import Spacer from '../../components/spacer'
import Button from '../../components/button'
import styles from './OnBoarding.module.css'
import * as selectors from '../../store/selectors'

const OnBoarding = () => {
  const navigate = useNavigate()
  const { user } = useSelector(selectors.user)
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState(user.given_name)
  const [lastName, setLastName] = useState(user.family_name)
  const [function_, setFunction] = useState('')
  const onSubmit = async event => {
    event.preventDefault()
    const payload = {
      firstName,
      lastName,
      job: function_,
    }
    await dispatch(userActions.updateUserInfo(payload))
    navigate('/')
  }
  return (
    <Layout>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.title}>
          <Localized id="welcome">Bienvenue !</Localized>
        </div>
        <div className={styles.subtitle}>
          <Localized id="profile">Profil</Localized>
        </div>
        <div className={styles.spacer} />
        <TextInput
          placeholder="John"
          label="Prénom"
          value={firstName}
          onChange={setFirstName}
        />
        <TextInput
          placeholder="Doe"
          label="Nom"
          value={lastName}
          onChange={setLastName}
        />
        <TextInput
          placeholder="Manager"
          label="Fonction"
          value={function_}
          onChange={setFunction}
        />
        <Spacer size={36} />
        <Button title="Valider" primary />
      </form>
    </Layout>
  )
}

export default OnBoarding
