import React from 'react'
import styles from './Inputs.module.css'

const prependZero = value => {
  if (value < 10) {
    return `0${value}`
  } else {
    return value.toString()
  }
}

const toValue = value => {
  const year = value.getFullYear()
  const month = prependZero(value.getMonth() + 1)
  const day = prependZero(value.getDate())
  return [year, month, day].join('-')
}

const dateHandler = onChange => event => {
  const [year, month, day] = event.target.value
    .split('-')
    .map(i => parseInt(i, 10))
  const date = new Date(Date.UTC(year, month - 1, day))
  onChange && onChange(date)
}

const DateInput = ({ label, value, ...props }) => {
  const wrapper = props.multiline ? styles.textAreaWrapper : styles.inputWrapper
  const onChange = dateHandler(props.onChange)
  return (
    <label className={styles.label}>
      {label && <div className={styles.labelInput}>{label}</div>}
      <div className={wrapper}>
        {props.topContent}
        <input
          type="date"
          className={`${styles.dateInput} ${styles.textInput}`}
          onChange={onChange}
          placeholder={props.placeholder}
          value={toValue(value)}
          disabled={props.disabled}
        />
      </div>
    </label>
  )
}

export default DateInput
