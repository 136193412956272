import React from 'react'
import styles from './Inputs.module.css'

const TextInput = ({ label, value, ...props }) => {
  const onChange = event => props.onChange && props.onChange(event.target.value)
  const wrapper = props.multiline ? styles.textAreaWrapper : styles.inputWrapper
  return (
    <label className={styles.label}>
      {label && <div className={styles.labelInput}>{label}</div>}
      <div className={wrapper}>
        {props.topContent}
        {React.createElement(props.multiline ? 'textarea' : 'input', {
          rows: 10,
          className: styles.textInput,
          onKeyDown: props.onKeyDown,
          onChange: onChange,
          placeholder: props.placeholder,
          value: value,
          disabled: props.disabled,
          style: { textAlign: props.multiline ? 'left' : undefined },
        })}
      </div>
    </label>
  )
}

export default TextInput
