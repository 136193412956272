import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as selectors from '../../store/selectors'
import Logo from '../logo'
import * as Icons from '../../icons'
import styles from './Layout.module.css'

const Layout = ({ children, title, overflow = 'hidden', back, cancel }) => {
  const { user } = useSelector(selectors.user)
  const navigate = useNavigate()
  const navigateToHome = () => navigate('/')
  const navigateToProfile = () => navigate('/profile')
  return (
    <Fragment>
      <nav className={styles.navbar}>
        <Logo small onClick={navigateToHome} />
        <div className={styles.spacer} />
        <div className={styles.hello}>
          Bonjour {user.given_name || user.nickname} !
        </div>
        <Logo small url={user.picture} onClick={navigateToProfile} />
      </nav>
      <div className={styles.title}>
        {(back || cancel) && (
          <button onClick={() => navigate(-1)}>
            {back && <Icons.ArrowLeft className={styles.arrowLeftIcon} />}
            {cancel && <Icons.Delete className={styles.deleteIcon} />}
          </button>
        )}
        <div className={styles.titleText}>{title}</div>
        {back && <div className={styles.titlePlaceholder} />}
        {cancel && <div className={styles.cancelPlaceholder} />}
      </div>
      <div className={styles.body} style={{ overflow }}>
        <div className={styles.bodyWrapper}>{children}</div>
      </div>
    </Fragment>
  )
}

export default Layout
