import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import settings from './reducers/settings'
import projects from './reducers/projects'

const reducer = combineReducers({
  settings,
  projects,
})

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)))

export default store
