const SvgComponent = props => (
  <svg
    width={41}
    height={28}
    viewBox="0 0 41 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{'noun_payment_2955769'}</title>
    <g fill="var(--text-color)" fillRule="nonzero">
      <path d="M40.444 4.667A4.667 4.667 0 0035.778 0H4.667A4.667 4.667 0 000 4.667v1.555h40.444V4.667zM0 23.333A4.667 4.667 0 004.667 28h31.11a4.667 4.667 0 004.667-4.667v-14H0v14zm6.222-7.777h3.111a1.556 1.556 0 110 3.11h-3.11a1.556 1.556 0 010-3.11zm0 4.666h9.334a1.556 1.556 0 010 3.111H6.222a1.556 1.556 0 110-3.11z" />
    </g>
  </svg>
)

export default SvgComponent
