const SvgComponent = props => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <filter id="prefix__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      transform="translate(-1274 -356)"
      fill="var(--text-color)"
      fillRule="evenodd"
    >
      <path
        d="M1285.384 366.554c.558.558-.28 1.378-.83.83l-4.625-4.624-4.625 4.624a.585.585 0 01-.415.178c-.478 0-.816-.606-.415-1.008l4.625-4.625-4.625-4.625c-.552-.552.278-1.382.83-.83l4.625 4.625 4.625-4.625c.554-.553 1.382.278.83.83l-4.624 4.625 4.624 4.625z"
        fill="var(--text-color)"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgComponent
