import React from 'react'
import Logo from '../logo'
import styles from './styles.module.css'
import go1 from '../../assets/go1.svg'
import go2 from '../../assets/go2.svg'
import go3 from '../../assets/go3.svg'
import * as Icons from '../../icons'

const Layout = ({ children }) => (
  <div className={styles.layout}>
    <Icons.Patate1 className={styles.patate1} />
    <Icons.Patate2 className={styles.patate2} />
    <div className={styles.logo}>
      <Logo />
    </div>
    <img className={styles.go1} src={go1} alt="Gentille personne 1" />
    <img className={styles.go2} src={go2} alt="Gentille personne 2" />
    <img className={styles.go3} src={go3} alt="Gentille personne 3" />
    <div className={styles.socialButtons}>{children}</div>
  </div>
)

export default Layout
