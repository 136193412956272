import * as React from 'react'

const SvgComponent = ({ bookColor, bookStrapColor, ...props }) => (
  <svg width={140} height={303} viewBox="0 0 140 303" {...props}>
    <g fillRule="nonzero" fill="none">
      <ellipse fill="#E6E6E6" cx={70} cy={296} rx={70} ry={7} />
      <path
        d="M46.674 47.812L50.935 90H86l-1.685-64.125C84.315 12.073 74.785 0 61 0 47.215 0 38.247 11.51 38.247 25.312L36 90h5.717l4.957-42.188z"
        fill="#2F2E41"
      />
      <path
        d="M67.334 269s-1.521 9.5-4.057 11c-2.536 1.5 13.187 5 13.187 5L79 270l-11.666-1z"
        fill="#FBBEBE"
      />
      <path
        d="M85.413 159L91 165.587l-2.54 22.8-2.031 45.096s4.063 39.014 0 40.028c-4.064 1.013-22.35.506-21.842-2.027.508-2.533 1.016-4.56 2.032-8.107 1.016-3.547-1.016-16.214-1.016-16.214l2.54-14.187L59 171.667 85.413 159zM66.894 278.435s-5.548-.98-6.053 0c-.504.98-10.088 11.262-11.601 11.262-1.513 0-12.61 9.794-1.009 10.283 11.601.49 30.769-8.324 30.769-8.324s-2.82-12.46-2.671-11.861c.149.599-9.435 1.089-9.435-1.36z"
        fill="#2F2E41"
      />
      <path
        d="M61 255.104s14.275 15.701 14.784 17.22c.51 1.52 8.157 0 8.157 0L87 262.195l-2.549-3.545L70.176 247 61 255.104z"
        fill="#FBBEBE"
      />
      <path
        d="M81.519 266.758L74 273.685s9.593 14.391 9.593 15.914c0 1.522.518 14.212 5.703 12.182C94.481 299.75 95 279.447 95 279.447s-.519-20.81-3.63-22.333c-3.11-1.523-9.93-1.042-9.93-1.042s4.227 10.178.079 10.686zM39.46 114.102s-2.527 8.126-3.032 10.158c-.505 2.031-3.537 7.618-3.537 16.253 0 8.634 1.01 18.792 0 19.808-1.01 1.016-17.18 57.902-10.106 63.997 7.074 6.094 30.318 26.41 31.834 28.95 1.515 2.54 2.02 8.635 5.053 7.619 3.031-1.016 14.653-11.174 15.158-13.206.506-2.031-6.568-5.587-6.568-5.587s-14.149-20.824-17.686-20.316c-3.537.508-3.032-2.54-2.526-4.063.505-1.524 1.516-2.54 0-2.54s-.506-4.063 0-6.095c.505-2.031 12.127-29.458 12.127-29.458L91 165.908s-1.516-37.077-12.632-47.235l-2.527-6.095s-28.297-2.032-36.381 1.524z"
        fill="#2F2E41"
      />
      <path
        d="M52.675 34s2.53 17.747-3.542 18.76C43.06 53.776 38 53.269 38 53.776 38 54.282 53.18 70 53.18 70l14.17-1.014L80 55.296s-11.639-2.028-11.639-9.127S67.855 34 67.855 34h-15.18z"
        fill="#FFB8B8"
      />
      <circle fill="#FFB8B8" cx={61} cy={26} r={16} />
      <path
        d="M51.167 51s-24.512 2.298-26.03 8.87c-1.52 6.573 10.126 47.524 11.645 51.063 1.52 3.54 1.013 6.067 1.013 6.067a20.059 20.059 0 0116.203-3.539c9.62 2.022 23.292 1.011 23.292 1.011s4.558-18.706 5.57-20.728c1.013-2.022 5.064-13.145 5.064-16.178 0-3.034 6.076-15.673 6.076-15.673s-3.038-9.1-14.178-8.595c0 0-4.859-2.171-6.227-.58-1.368 1.591-8.963 8.67-18.584 5.636C45.391 55.32 51.167 51 51.167 51z"
        fill="#E6E6E6"
      />
      <path
        d="M85 59l9.578 2.5s7.057 25.5 7.56 26c.505.5 1.01 3.5 1.01 3.5s0 .5 1.007 2c1.008 1.5 2.52.5 1.513 2.5-1.009 2-2.52 1-1.513 3 1.008 2 .504 7.5.504 7.5s-2.52-9.5-11.594-7l-6.049-27L85 59z"
        fill="#E6E6E6"
      />
      <path
        fill={bookColor}
        d="M87 64.556l-.035.115L70.075 117l-11.086-2.422L47 111.961 63.925 58z"
      />
      <path
        d="M36 59l-9.578 2.5s-7.057 25.5-7.56 26c-.505.5-1.01 3.5-1.01 3.5s0 .5-1.007 2c-1.008 1.5-2.52.5-1.513 2.5 1.009 2 2.52 1 1.513 3-1.008 2-.504 7.5-.504 7.5s2.52-9.5 11.594-7l6.049-27L36 59z"
        fill="#E6E6E6"
      />
      <path
        d="M19.237 99.535l-3.127 5.382S14.732 115 21.74 115s17.02-9.795 17.02-9.795 5.005-2.062 8.009-1.546c3.003.515 19.523 0 12.514-8.764-7.008-8.764-19.022 0-19.022 0l-12.514 6.186-6.508-3.608-2.002 2.062z"
        fill="#FFB8B8"
      />
      <path
        d="M22.85 91.98L28 99.814s-11.845 1.47-12.875 3.918C14.095 106.18 19.76 91 19.76 91l3.09.98z"
        fill="#E6E6E6"
      />
      <path
        d="M47.902 21c10.08-5.46 14.22-5.229 26.754 0L78 12.814c-.004-3.21-2.592-5.81-5.787-5.814H49.787c-3.195.004-5.783 2.604-5.787 5.814L47.902 21z"
        fill="#2F2E41"
      />
      <path
        fill={bookStrapColor}
        d="M87 65.18L70.096 117 59 114.602 75.676 62z"
      />
      <path fill={bookColor} d="M79.923 62L63 115.958 86.077 121 103 68.555z" />
      <path
        d="M100.763 99.535l3.127 5.382S105.268 115 98.26 115s-17.02-9.795-17.02-9.795-5.005-2.062-8.009-1.546c-3.003.515-19.523 0-12.514-8.764 7.008-8.764 19.022 0 19.022 0l12.514 6.186 6.508-3.608 2.002 2.062z"
        fill="#FFB8B8"
      />
      <path
        d="M96.15 91.98L91 99.814s11.845 1.47 12.875 3.918C104.905 106.18 99.24 91 99.24 91l-3.09.98z"
        fill="#E6E6E6"
      />
    </g>
  </svg>
)

export default SvgComponent
