import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import Layout from '../../components/layout'
import Card from '../../components/card'
import Button from '../../components/button'
import TextInput from '../../components/text-input'
import DateInput from '../../components/date-input'
import ColorInput from '../../components/color-input'
import * as Icons from '../../icons'
import styles from './Project.module.css'
import * as project from '../../store/actions/project'

const DisplayCollabs = ({ collabs, onRemove }) => (
  <div className={styles.pills}>
    {collabs.map(collab => (
      <div className={styles.collabPill}>
        <div className={styles.collabPillText}>{collab}</div>
        <Icons.Cross
          className={styles.crossIcon}
          onClick={() => onRemove(collab)}
        />
      </div>
    ))}
  </div>
)

export const NewProject = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [date, setDate] = useState(new Date())
  const [color, setColor] = useState()
  const [collabsText, setCollabsText] = useState('')
  const [collabs, setCollabs] = useState([])
  const onEnter = event => {
    if (event.keyCode === 13) {
      event.preventDefault()
      setCollabs(c => [...new Set([...c, collabsText])])
      setCollabsText('')
    }
  }
  const onRemove = name => setCollabs(c => c.filter(n => n !== name))
  const validateDisabled = name.length < 1 || !color
  const onSubmit = () => {
    dispatch(project.createProject({ name, date, color, collabs }))
    navigate('/')
  }
  return (
    <Layout title="Créer un projet" cancel>
      <Card color="var(--grey)">
        <Card.Body splitted>
          <div className={styles.informations}>
            <div className={styles.titleCollabsInfo}>Informations</div>
            <TextInput
              value={name}
              onChange={setName}
              label="Nom du projet :"
              placeholder="Moteur de recherche"
            />
            <DateInput
              value={date}
              onChange={setDate}
              label="Date du début :"
              placeholder="32 / 02 / 2045"
            />
            <ColorInput
              value={color}
              onChange={setColor}
              label="Couleur attribuée :"
            />
          </div>
          <div className={styles.collabs}>
            <div className={styles.titleCollabsInfo}>Collaborateurs</div>
            <TextInput
              topContent={<DisplayCollabs {...{ collabs, onRemove }} />}
              onChange={setCollabsText}
              value={collabsText}
              onKeyDown={onEnter}
              multiline
              label="Ajoutez vos collaborateurs :"
              placeholder="Pour ajouter des collaborateurs, veuillez remplir les renseignements bancaires"
            />
          </div>
        </Card.Body>
        <div className={styles.createButton}>
          <Button
            title="Créer"
            disabled={validateDisabled}
            onClick={onSubmit}
          />
        </div>
      </Card>
    </Layout>
  )
}
