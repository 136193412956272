const SvgComponent = props => (
  <svg
    width={60}
    height={45}
    viewBox="0 0 60 45"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.683 1.046C3.513 1.046.2 4.722.2 9.187v27.139c0 4.465 3.312 8.141 7.483 8.141h43.534c4.17 0 7.483-3.676 7.483-8.141V9.187c0-4.465-3.312-8.141-7.483-8.141H7.683zm0 1.357h15.964c-2.64 1.74-4.4 4.994-4.4 8.82v3.18a4.88 4.88 0 00-2.041-.467H1.56V9.187c0-3.803 2.756-6.784 6.123-6.784zm20.407 0h2.72c4.112 0 7.483 3.713 7.483 8.82v4.092a5.31 5.31 0 00-.489.508c-.976 1.2-1.552 2.891-1.552 4.898v3.392c0 2.007.576 3.678 1.552 4.877.155.19.314.387.489.551v4.75c0 5.106-3.371 8.82-7.483 8.82h-2.72c-4.112 0-7.483-3.714-7.483-8.82v-4.75c.175-.164.334-.361.489-.551.976-1.199 1.552-2.87 1.552-4.877v-3.392c0-2.007-.576-3.699-1.552-4.898a5.352 5.352 0 00-.489-.53v-4.07c0-5.107 3.371-8.82 7.483-8.82zm7.163 0h15.964c3.367 0 6.123 2.98 6.123 6.784v4.75H41.694a4.88 4.88 0 00-2.04.466v-3.18c0-3.826-1.762-7.08-4.4-8.82zM1.56 15.293h15.646c1.116 0 2.103.464 2.848 1.379.745.914 1.233 2.297 1.233 4.05v3.391c0 1.752-.488 3.114-1.233 4.029-.745.914-1.732 1.4-2.848 1.4H1.56V15.292zm40.134 0H57.34v14.248H41.694c-1.116 0-2.103-.485-2.848-1.4-.745-.914-1.233-2.276-1.233-4.028v-3.392c0-1.752.488-3.135 1.233-4.05.745-.914 1.732-1.378 2.848-1.378zm-22.447 15.16v3.837c0 3.817 1.75 7.077 4.379 8.82H7.683c-3.367 0-6.123-2.98-6.123-6.784v-5.428h15.646c.721 0 1.408-.15 2.04-.445zm20.406 0c.633.295 1.32.445 2.041.445H57.34v5.428c0 3.803-2.756 6.784-6.123 6.784H35.274c2.63-1.743 4.38-5.003 4.38-8.82v-3.837z"
      fill="var(--text-color)"
      fillRule="nonzero"
      opacity={0.99}
    />
  </svg>
)

export default SvgComponent
