import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Localized } from '@fluent/react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import Layout from '../../components/on-boarding-layout'
import { useOverlay } from '../../components/overlay'
import * as authActions from '../../store/actions/auth'
import { login } from '../../services/auth0'
import styles from './Login.module.css'
import appleLogo from '../../assets/apple.svg'
import microsoftLogo from '../../assets/microsoft.svg'
import googleLogo from '../../assets/google.svg'

const selectSocialButtonContent = provider => {
  switch (provider) {
    case 'apple':
      return 'Continuer avec Apple'
    case 'google':
      return 'Continuer avec Google'
    case 'microsoft':
      return 'Continuer avec Microsoft'
    default:
      return 'Meh ?'
  }
}

const selectSocialButtonIcon = provider => {
  switch (provider) {
    case 'apple':
      return { icon: appleLogo, alt: 'apple' }
    case 'google':
      return { icon: googleLogo, alt: 'google' }
    case 'microsoft':
      return { icon: microsoftLogo, alt: 'microsoft' }
    default:
      return 'Meh ?'
  }
}

const SocialButton = ({ provider, onClick }) => {
  const content = selectSocialButtonContent(provider)
  const { icon, alt } = selectSocialButtonIcon(provider)
  return (
    <button className={styles.socialButton} onClick={onClick}>
      <img className={styles.socialIcon} src={icon} alt={alt} />
      <div>{content}</div>
    </button>
  )
}

const Login = () => {
  const auth0 = useAuth0()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const socialClick = connection => () => login(auth0, connection)
  const overlay = useOverlay()
  useEffect(() => {
    const authenticateUser = async () => {
      if (auth0.isAuthenticated) {
        const token = await auth0.getAccessTokenSilently({})
        const uid = auth0.user.sub
        const user = { uid, ...auth0.user }
        const result = await dispatch(authActions.login(user, token))
        if (!result) {
          navigate('/onboarding', { replace: true })
        }
      }
      overlay.hide()
    }
    authenticateUser()
  }, [
    dispatch,
    navigate,
    overlay,
    auth0,
    auth0.isAuthenticated,
    auth0.isLoading,
    auth0.getAccessTokenSilently,
  ])
  return (
    <Layout>
      <div className={styles.title}>
        <Localized id="welcome">Bienvenue !</Localized>
      </div>
      <div className={styles.subtitle}>
        <Localized id="sign-in">Inscription / Connexion</Localized>
      </div>
      <div className={styles.spacer} />
      <SocialButton onClick={socialClick('apple')} provider="apple" />
      <SocialButton onClick={socialClick('google-oauth2')} provider="google" />
      <SocialButton onClick={socialClick('windowslive')} provider="microsoft" />
    </Layout>
  )
}

export default Login
