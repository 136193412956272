import env from '../envs'
import { bearer } from './token'
const route = 'time/user-info'

export const get = async token => {
  const options = { headers: bearer(token) }
  const response = await fetch([env.audience, route].join('/'), options)
  return response.json()
}

export const update = async (token, body) => {
  const method = 'POST'
  const options = { headers: bearer(token), body: JSON.stringify(body), method }
  const endpoint = [env.audience, route, 'create'].join('/')
  const response = await fetch(endpoint, options)
  return response.json()
}
