import * as types from '../types'

const initialState = {
  user: null,
  token: null,
  userInfo: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN: {
      const { user, token } = action.payload
      return { ...state, user, token }
    }
    case types.LOGOUT:
      return { ...state, user: null, token: null }
    case types.UPDATE_USER_INFO: {
      const userInfo = action.payload
      return { ...state, userInfo }
    }
    default:
      return state
  }
}

export default reducer
