import { useState, useContext, createContext } from 'react'
import styles from './Modal.module.css'

const ModalContext = createContext()

const emptyState = {
  visible: false,
  child: null,
  options: {},
  props: {},
}

const resolve = { value: null }

const ClickOverlay = ({ onClick }) => (
  <div className={styles.clickOverlay} onClick={onClick} />
)

const ModalOverlay = ({ onClose, children }) => (
  <div className={styles.overlay}>
    <ClickOverlay onClick={onClose} />
    <div className={styles.child}>{children}</div>
  </div>
)

const RenderChildren = ({ children, transparent, onClose }) => {
  if (children) {
    if (transparent) {
      return <ModalOverlay onClose={onClose}>{children}</ModalOverlay>
    } else {
      return children
    }
  } else {
    return null
  }
}

const Provider = ({ children }) => {
  const [{ child, visible, options, props }, setState] = useState(emptyState)
  const onRequestClose = value => {
    resolve.value && resolve.value(value ?? false)
    resolve.value = null
    setState(emptyState)
  }
  const onFalse = () => onRequestClose(false)
  const onValue = value => onRequestClose(value)
  const transparent = options.transparent || false
  const Child = child
  const c = child
  return (
    <ModalContext.Provider value={setState}>
      {visible && (
        <div>
          {child && (
            <RenderChildren transparent={transparent} onClose={onFalse}>
              {typeof c === 'function' ? (
                <Child close={onValue} {...props} />
              ) : (
                c
              )}
            </RenderChildren>
          )}
        </div>
      )}
      {children}
    </ModalContext.Provider>
  )
}

const useModal = () => {
  const setState = useContext(ModalContext)
  return {
    show(child, options, props = {}) {
      if (resolve.value) resolve.value(false)
      return new Promise(resolve_ => {
        resolve.value = resolve_
        const visible = true
        setState({ child, visible, options, props })
      })
    },
    dismiss(value) {
      resolve.value && resolve.value(value ?? false)
      resolve.value = null
      setState(emptyState)
    },
  }
}

export { Provider, useModal }
