const SvgComponent = props => (
  <svg
    width={27}
    height={29}
    viewBox="0 0 27 29"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <filter id="prefix__a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g transform="translate(-9 -9)" fill="none" fillRule="evenodd">
      <path
        d="M22.5 9.386a2.314 2.314 0 00-2.314 2.314v8.871h-8.872a2.314 2.314 0 100 4.629h8.872v8.871a2.314 2.314 0 004.628 0V25.2h8.872a2.314 2.314 0 000-4.629h-8.872V11.7A2.314 2.314 0 0022.5 9.386z"
        fill="var(--text-color)"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgComponent
