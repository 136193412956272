import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as selector from '../../../store/selectors'
import * as plage from '../../../helpers/plage'
import { getPlage } from '../../../helpers/plage'
import styles from '../Project.module.css'

const correspPlage = [
  {
    key: plage.ALL,
    val: 'Toute la mission',
  },
  {
    key: plage.MONTHS,
    val: 'Mois',
  },
  {
    key: plage.THIRTYD,
    val: '30j',
  },
  {
    key: plage.WEEK,
    val: 'Semaine',
  },
  {
    key: plage.CUSTOM,
    val: 'Personnalisé',
  },
]

export const Stats = () => {
  const { id } = useParams()
  const [plage, setPlage] = useState(plage.ALL)
  const project = useSelector(selector.project(id))
  const daysOrHours = 'days' //TODO un settings pour savoir quoi afficher?
  const displayVal = val =>
    val.toPrecision(Math.max(Math.floor(Math.log10(Math.abs(val))), 0) + 2) +
    ' j'

  const now = new Date(Date.now())
  const { intervals: currentPlage, data } = getPlage({
    timeseries: project.workedTime,
    type: plage,
    end: now,
    start: plage === plage.ALL ? project.createdAt : undefined,
  })
  const state = {
    series: [{ data: data[daysOrHours] }],
    options: {
      colors: [
        `var(--${project.info.color})`,
        `var(--darken-${project.info.color})`,
        `var(--lighten-${project.info.color})`,
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: false,
        },
      },
      dataLabels: {
        formatter: displayVal,
      },
      xaxis: {
        type: 'datetime',
        categories: currentPlage.map(
          d => new Intl.DateTimeFormat('en-us').format(d) + ' GMT'
        ),
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: displayVal,
        },
      },
      legend: {
        position: 'bottom',
      },
      fill: {
        opacity: 1,
      },
    },
  }
  const plages = [
    plage.ALL,
    plage.MONTHS,
    plage.THIRTYD,
    plage.WEEK,
    plage.CUSTOM,
  ]
  const tradPlage = p => correspPlage.find(({ key }) => p === key)?.val

  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Statistiques</div>
      </div>
      <div className={styles.choosePlage}>
        Plage temporelle:
        <div className={styles.plages}>
          {plages.map(p => (
            <div
              key={p}
              style={
                p === plage
                  ? {
                      background: `var(--lighten-${project.info.color})`,
                      border: `solid var(--lighten-${project.info.color})`,
                    }
                  : {}
              }
              onClick={() => setPlage(p)}
              className={styles.plage}
            >
              {tradPlage(p)}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.chart}>
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  )
}
