import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as selectors from '../store/selectors'

export const useProtectedRoute = () => {
  const navigate = useNavigate()
  const { user } = useSelector(selectors.user)
  if (!user) {
    navigate('/')
  }
}
