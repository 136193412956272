const SvgComponent = props => (
  <svg width={193} height={354} viewBox="0 0 193 354" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        fill="#FFB9B9"
        d="M115.75 291l8.25 4.4-5.25 16.867-8.25.733-4.5-9.533zM89 326v8l-13-.727.181-6.59z"
      />
      <path
        fill="#2F2E41"
        d="M118 191l-4.472 56.757L99.364 331 77 327.973l7.454-65.838-2.982-60.54z"
      />
      <path
        d="M58.32 148l54.612.757L119 190.385l-37.167 17.409-9.102 33.302L119 290.294 100.796 313s-25.79-34.817-44.752-54.495c-18.962-19.68-9.86-79.473-9.86-79.473L58.32 148z"
        fill="#2F2E41"
      />
      <circle fill="#FFB9B9" cx={90.5} cy={24.5} r={16.5} />
      <path
        d="M97 37.5V54L85.431 72S67.692 59.25 71.55 56.25c3.856-3 4.627-26.25 4.627-26.25L97 37.5z"
        fill="#FFB9B9"
      />
      <path
        fill="#FFF"
        d="M96.688 50.264L108 63.825 103.475 158l-35.442-.753L62 75.127l7.54-21.096L74.714 47l9.156 13.812z"
      />
      <path
        d="M114 98.824l8.412 31.568L114 161.961 127.765 180 140 122.876s-9.177-46.601-9.941-51.863c-.765-5.261-2.294-6.013-2.294-6.013l-6.118.752L114 98.824zM54 111.75l-5.303 9.75L47.18 138l3.03 18.75L39.607 174 29 126.75 40.363 66h4.546zM118.507 290s10.507 2.997 14.26 3.746c3.752.75-2.252 14.986-2.252 14.986s-3.753 19.48-4.503 23.227c-.75 3.746-6.004 16.484-12.759 17.982-6.754 1.499-2.251-26.224-2.251-26.224S108 314.726 108 311.729s4.503-1.499 4.503-1.499c12.008-1.498 6.004-20.23 6.004-20.23zM93.9 347.648c-.762 4.643.76 4.643-11.423 6.19-12.185 1.548-9.139-8.51-9.139-8.51a65.902 65.902 0 013.047-13.928c2.284-6.19 14.468 1.548 14.468 1.548s3.808 10.058 3.046 14.7zM77.045 29.424L76.652 36s-4.866-10.79-5.62-21.88C70.278 3.027 83.098.81 90.639.07 98.18-.668 111 4.508 111 10.423c0 5.916-13.574 4.437-18.853 2.958-5.279-1.479-7.541 11.091-7.541 11.091l-3.017.74-4.544 4.213z"
        fill="#2F2E41"
      />
      <path
        fill="#FFF"
        d="M101 54.447L96.361 49 83 59.77 96.361 69zM69 53.827L74.158 47 83 60.655 71.947 69z"
      />
      <path
        d="M116.333 109.75l-3.048 28.678 6.095 17.358 3.81 16.603s0 15.848-15.239 16.602c-15.24.755-28.193-47.544-28.193-47.544s-2.285 10.565-3.81 16.603c-1.523 6.037-27.43 30.187-27.43 30.187s-6.096-.755-11.43-12.83c-5.333-12.074 10.668-23.395 10.668-23.395l4.572-15.848-1.524-19.621-10.668-51.318L71.758 49l-3.43 43.394 11.43 33.96 17.144-75.09 5.715 4.15L127 65.226l-10.667 44.526z"
        fill="#2F2E41"
      />
      <path fill="#3F3D56" d="M192.714 354H0v-1h193z" />
    </g>
  </svg>
)

export default SvgComponent
