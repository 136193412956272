import styles from './Inputs.module.css'

const colors = [
  'blue',
  'red',
  'yellow',
  'cyan',
  'green',
  'orange',
  'pink',
  'purple',
]

const renderColor = (value, onChange) => color => {
  const cl = value === color ? styles.activePoint : styles.inactivePoint
  const pointStyle = { background: `var(--${color})` }
  return (
    <div onClick={() => onChange(color)} className={cl} key={color}>
      <div style={pointStyle} className={styles.colorPoint} />
    </div>
  )
}

const TextInput = ({ label, value, ...props }) => {
  const onChange = value => props.onChange && props.onChange(value)
  return (
    <label className={styles.label}>
      {label && <div className={styles.labelInput}>{label}</div>}
      <div className={styles.colorWrapper}>
        {colors.map(renderColor(value, onChange))}
      </div>
    </label>
  )
}

export default TextInput
