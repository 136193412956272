import 'intl-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store'
import './index.css'
import App from './App'
import * as Overlay from './components/overlay'
import * as Modal from './components/modal'
import reportWebVitals from './reportWebVitals'
import { negotiateLanguages } from '@fluent/langneg'
import { FluentBundle, FluentResource } from '@fluent/bundle'
import { LocalizationProvider, ReactLocalization } from '@fluent/react'
import { Auth0Provider } from '@auth0/auth0-react'
import env from './envs'

// Store all translations as a simple object which is available
// synchronously and bundled with the rest of the code.
const RESOURCES = {
  fr: new FluentResource('hello = Salut le monde !'),
  en: new FluentResource('hello = Hello, world!'),
}

// A generator function responsible for building the sequence
// of FluentBundle instances in the order of user's language
// preferences.
function* generateBundles(userLocales) {
  // Choose locales that are best for the user.
  const currentLocales = negotiateLanguages(userLocales, ['fr', 'en'], {
    defaultLocale: 'en',
  })

  for (const locale of currentLocales) {
    const bundle = new FluentBundle(locale)
    bundle.addResource(RESOURCES[locale])
    yield bundle
  }
}

// The ReactLocalization instance stores and caches the sequence of generated
// bundles. You can store it in your app's state.
let l10n = new ReactLocalization(generateBundles(navigator.languages))

if (window.safari) {
  const [body] = [...document.getElementsByTagName('body')]
  if (body) {
    body.setAttribute('class', 'safari')
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={env.domain}
      clientId={env.clientId}
      scope="openid profile email offline_access"
      redirectUri={window.location.origin}
      audience={env.audience}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <LocalizationProvider l10n={l10n}>
        <Modal.Provider>
          <Provider store={store}>
            <Overlay.Provider>
              <App />
            </Overlay.Provider>
          </Provider>
        </Modal.Provider>
      </LocalizationProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
