import faker from 'faker'
import env from '../envs'
import { bearer } from './token'
import { randomColor } from '../helpers/colors'
const route = 'time/project'

const randomProject = () => ({
  title: faker.commerce.department(),
  color: randomColor(),
  id: faker.datatype.uuid(),
  collaborators: Array(Math.floor(Math.random() * 8))
    .fill(1)
    .map(_ => ({ name: faker.name.findName() })),
  owner: faker.name.findName(),
  time: [],
  createdAt: faker.date.past(),
})

const toJS = jsonObject => ({
  ...jsonObject,
  created_at: undefined,
  updated_at: undefined,
  owner_info: undefined,
  createdAt: new Date(jsonObject.created_at),
  updatedAt: new Date(jsonObject.updated_at),
  ownerInfo: jsonObject.owner_info,
})

export const get = async (token, id) => {
  const options = { headers: bearer(token) }
  const endpoint = [env.audience, route, 'all'].join('/')
  const parameters = `owner=${id}`
  const response = await fetch(`${endpoint}?${parameters}`, options)
  const res = await response.json()
  return res.map(toJS)
}

export const update = async (token, body) => {
  const method = 'POST'
  const options = { headers: bearer(token), body: JSON.stringify(body), method }
  const endpoint = [env.audience, route, 'update'].join('/')
  const response = await fetch(endpoint, options)
  return response.json()
}

export const create = async (token, body) => {
  const method = 'POST'
  const options = { headers: bearer(token), body: JSON.stringify(body), method }
  const endpoint = [env.audience, route, 'create'].join('/')
  const response = await fetch(endpoint, options)
  return response.json()
}
