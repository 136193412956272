import { Routes, Route } from 'react-router-dom'
import { NewProject } from './new'
import { ID } from './[id]'

export const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path="/new" element={<NewProject />} />
      <Route path="/:id/*" element={<ID />} />
    </Routes>
  )
}
