import * as types from '../types'
import { get, getAll } from '../../services/workedTime'

export const fetchProjectWorkedTime = project => async (dispatch, getState) => {
  const state = getState()
  const { token } = state.settings
  if (token) {
    const workedTime = await get(token, project)
    await dispatch({
      payload: { id: project, workedTime },
      type: types.FETCH_PROJECT_WORKED_TIME,
    })
  }
}

const groupByProjectId = (acc, val) => {
  if (acc[val.pid]) {
    acc[val.pid].push(val)
  } else {
    acc[val.pid] = [val]
  }
  return acc
}

export const fetchWorkedTime = () => async (dispatch, getState) => {
  const state = getState()
  const { token, user } = state.settings
  if (token) {
    const workedTime = await getAll(token, user.uid)
    if (workedTime.length > 0) {
      const workedTimePerProject = workedTime.reduce(groupByProjectId, {})
      await Promise.all(
        Object.entries(workedTimePerProject).map(([id, workedTime]) =>
          dispatch({
            payload: { workedTime, id },
            type: types.FETCH_PROJECT_WORKED_TIME,
          })
        )
      )
    }
  }
}
