import * as types from '../types'
import * as project from '../../services/project'

export const fetchProjects = () => async (dispatch, getState) => {
  const state = getState()
  const { token, user } = state.settings
  if (token) {
    const projects = await project.get(token, user.uid)
    await dispatch({
      payload: projects,
      type: types.FETCH_PROJECTS,
    })
  }
}

export const updateProjectTitle = ({ id, title }) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { token } = state.settings
    const project_ = state.projects.find(p => p.id === id)
    await project.update(token, { info: { ...project_.info, title }, id })
    const payload = { ...project_, info: { ...project_.info, title } }
    await dispatch({ type: types.UPDATE_PROJECT, payload })
    return payload
  }
}

export const createProject = body => async (dispatch, getState) => {
  const state = getState()
  const { token, user } = state.settings
  const payload = await project.create(token, {
    info: { title: body.name, color: body.color, collaborators: body.collabs },
    owner: user.uid,
  })
  console.log(payload)
  return payload
}
