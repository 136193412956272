import React from 'react'
import styles from './Card.module.css'

const computeShadow = shadow => {
  switch (shadow) {
    case 'hard':
      return 'var(--box-shadow)'
    case 'light':
      return 'var(--box-shadow-light)'
    case 'none':
      return 'none'
    default:
      return 'var(--box-shadow)'
  }
}

const Card = ({ color, children, shadow = 'hard' }) => {
  const boxShadow = computeShadow(shadow)
  const style = { '--card-color': color, boxShadow }
  return (
    <div className={styles.cardWrapper} style={style}>
      <div className={styles.edgingColor} />
      <div className={styles.card}>{children}</div>
    </div>
  )
}

Card.Nav = ({ children }) => <div className={styles.nav}>{children}</div>

const Splitted = ({ children }) => (
  <div className={styles.flexer}>
    {children.map((child, index) => (
      <div key={index} className={styles.splitItem}>
        {child}
      </div>
    ))}
  </div>
)

const computeAlignItems = align => {
  switch (align) {
    case 'center':
      return 'center'
    default:
      return undefined
  }
}

Card.Body = ({ splitted, children, background, row, align, style }) => {
  const child = splitted ? <Splitted>{children}</Splitted> : children
  const clName = row ? styles.rowBody : styles.body
  const alignItems = computeAlignItems(align)
  return (
    <div className={clName} style={{ ...style, background, alignItems }}>
      {child}
    </div>
  )
}

export default Card
