import { useParams, Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Team } from './[id]/team'
import { Informations } from './[id]/informations'
import { Stats } from './[id]/stats'
import Layout from '../../components/layout'
import Card from '../../components/card'
import { useProtectedRoute } from '../../helpers/auth'
import { ProfileLink } from '../../components/profile-link'
import * as selector from '../../store/selectors'
import * as Icons from '../../icons'

export const ID = () => {
  useProtectedRoute()
  const { id } = useParams()
  const project = useSelector(selector.project(id))
  if (!project || !project.info) return <></>
  const color = `var(--${project.info.color})`
  const lightenColor = `var(--lighten-${project.info.color})`
  return (
    <Layout title={project.info.title} back>
      <Card color={color}>
        <Card.Nav>
          <ProfileLink
            to={`/project/${project.id}`}
            icon={Icons.Note}
            title="Infos"
            color={lightenColor}
          />
          <ProfileLink
            to={`/project/${project.id}/stats`}
            icon={Icons.Payment}
            title="Stats"
            color={lightenColor}
          />
          <ProfileLink
            to={`/project/${project.id}/team`}
            icon={Icons.Team}
            title="Équipe"
            color={lightenColor}
          />
        </Card.Nav>
        <Card.Body>
          <Routes>
            <Route path="/" element={<Informations />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/team" element={<Team />} />
          </Routes>
        </Card.Body>
      </Card>
    </Layout>
  )
}
