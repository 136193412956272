const SvgComponent = props => (
  <svg width={20} height={42} viewBox="0 0 20 42" {...props}>
    <path
      d="M18.9 38.272L5.145 21 18.9 3.728c.12-.151.19-.36.19-.577a.946.946 0 00-.184-.58L17.1.239c-.244-.316-.638-.318-.884-.008L.19 20.418A.943.943 0 000 21c0 .22.069.43.19.582L16.216 41.77a.564.564 0 00.44.231c.16 0 .322-.08.445-.238l1.805-2.332a.946.946 0 00.185-.58.941.941 0 00-.19-.578z"
      fill="var(--text-color)"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgComponent
