import {
  createContext,
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react'
import styles from './Overlay.module.css'
import loader from '../../assets/loader.gif'

const OverlayContext = createContext()

const isProd = process.env.NODE_ENV !== 'development'

const Overlay = ({ visible }) => {
  const [mounted, setMounted] = useState(isProd)
  const [opacity, setOpacity] = useState(isProd ? 1 : 0)
  useEffect(() => {
    if (mounted) setOpacity(1)
    if (visible) {
      setMounted(true)
    } else {
      setOpacity(0)
      const clearer = setTimeout(() => setMounted(false), 1000)
      return () => clearTimeout(clearer)
    }
  }, [visible, mounted])
  return (
    mounted && (
      <div className={styles.overlay} style={{ opacity }}>
        <div className={styles.title}>31 Days</div>
        <img className={styles.loader} src={loader} alt="loader" />
      </div>
    )
  )
}

const Provider = ({ children }) => {
  const [visible, setVisible] = useState(isProd)
  return (
    <OverlayContext.Provider value={[visible, setVisible]}>
      <Overlay visible={visible} />
      {children}
    </OverlayContext.Provider>
  )
}

const useOverlay = () => {
  // eslint-disable-next-line
  const [state, setState] = useContext(OverlayContext)
  const hide = useCallback(() => setState(s => (s ? false : s)), [setState])
  const show = useCallback(() => setState(s => (!s ? true : s)), [setState])
  return { hide, show }
}

export { Overlay, Provider, useOverlay }
