import * as types from '../types'
import * as userInfo from '../../services/user-info'

export const updateUserInfo = body => async (dispatch, getState) => {
  const state = getState()
  const { token } = state.settings
  await userInfo.update(token, body)
  await dispatch({
    payload: { ...state.settings.userInfo, info: body },
    type: types.UPDATE_USER_INFO,
  })
}

export const fetchUserInfo = userInfo => ({
  payload: userInfo,
  type: types.FETCH_USER_INFO,
})
