import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Card from '../../components/card'
import Layout from '../../components/layout'
import Project from './project'
import * as selectors from '../../store/selectors'
import * as Icons from '../../icons'
import styles from './Home.module.css'
import Patate from './patate4'

const noProjects =
  'Vous n’avez aucun projet en cours, créez votre premier projet'

const renderProject = p => <Project key={p.id} project={p} />

const Home = () => {
  const navigate = useNavigate()
  const projects = useSelector(selectors.projects)
  const isEmpty = !projects || projects.length <= 0
  const onNewProject = () => navigate('/project/new')
  return (
    <Layout title="Les Projets en cours" overflow="visible">
      {isEmpty && <div className={styles.placeholderTitle}>{noProjects}</div>}
      <div className={styles.gridProject}>
        {projects && projects.map(renderProject)}
        <Card color="var(--grey)" shadow="light">
          <Card.Body>
            <button className={styles.groPlus} onClick={onNewProject}>
              <Icons.Plus />
            </button>
          </Card.Body>
        </Card>
      </div>
      {isEmpty && <Icons.BusinessMan className={styles.businessMan} />}
      <Patate className={styles.patate} />
    </Layout>
  )
}

export default Home
