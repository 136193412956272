const SvgComponent = props => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="var(--text-color)" fillRule="nonzero">
      <path d="M26.45 21.61h-3.802c-.595 0-1.077.482-1.077 1.077v3.798a.359.359 0 00.614.255l4.516-4.52a.359.359 0 00-.252-.61z" />
      <path d="M26.237 0H1.71C.916 0 .273.643.273 1.436v24.84c0 .794.643 1.437 1.436 1.437h16.99a.718.718 0 00.718-.718v-4.308a3.23 3.23 0 013.23-3.23h4.308a.718.718 0 00.718-.719V1.436C27.673.643 27.03 0 26.237 0zM15.795 18.132H5.266a1.077 1.077 0 010-2.154h10.529a1.077 1.077 0 010 2.154zm7.18-5.385H5.265a1.077 1.077 0 010-2.154h17.708a1.077 1.077 0 010 2.154zm0-5.384H5.265a1.077 1.077 0 110-2.154h17.708a1.077 1.077 0 110 2.154z" />
    </g>
  </svg>
)

export default SvgComponent
